.image_bg {
	height: 0;
	padding-bottom: 42.3076%;
}
.home_bg_1 {
	background: url('../img/bg/baby-desktop-@1x.jpg') no-repeat center center;
	background-size: cover;
}
.home_bg_1.mobile {
	background: url('../img/bg/baby-mobile-@1x.jpg') no-repeat center center;
	height:350px;
	background-size: cover;
}
.home_bg_2 {
	background: url('../img/bg/contact-desktop-@1x.jpg') no-repeat center center;
	background-size: cover;
	padding-top: 13%;
	padding-bottom: 35%;
}
.home_bg_2.mobile {
	background: url('../img/bg/contact-mobile-@1x.jpg') no-repeat center center;
	height:100%;
	padding-top:70px;
    padding-bottom: 70px;
	background-size: cover;
}
.fetal_conditions_slider {
	margin-top:20px;
}
.fetal_condition_slide {
	position: relative;
	/*padding-bottom: 60px;*/
	padding-left: 60px;
	padding-right:50px;
}
.fetal_condition_slide h3 {
	text-transform: uppercase;
	font-size: 17px;
	height: 44px;
}
.fetal_condition_slide.fetal_condition_slide img{
    margin: 0 auto;
}
.fetal_condition_slide .btn {
	position: absolute;
    margin: 25px auto 0;
	max-width: 150px;
    left: 0;
    right: 0;
	text-align: center;
}
.icon-arrow-down {
	border: 2px solid white;	
	width: 50px;
	height: 50px;
	-o-border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	display: inline-block;
}
.home_specialised_service_column {
	margin-bottom: 32px;
}

.video_wrapper .icon-arrow-down:hover,
.video_wrapper .icon-arrow-down:active,
.video_wrapper .icon-arrow-down:focus { background-color:#ffffff; }

.home_specialised_services {margin-top:20px;}

body.home main#main {
	margin: 0;
}

.video_placeholder {
	height:485px;
	width:100%;
	position: relative;
	background:transparent url("../img/bg/video-placeholder-@1x.jpg") no-repeat center center;
	background-size:cover;
}

@media screen and (min-width: 992px){
	.home_specialised_services {
		display: flex;
		align-items: stretch;
	}
	.home_specialised_service_column {
		padding-bottom: 40px;
	}
	.home_specialised_service_column .btn {
		position: absolute;
		bottom: 0;
		left: 15px;
	}
	.fetal_condition_slide .btn {
        position: relative;
        margin: 25px 0 0;
	}
    .fetal_condition_slide.fetal_condition_slide img{
        margin: 0;
    }

}

@media (max-width:860px){
	.home_bg_2 {
		padding-top:10%;
		min-height:380px;
	}
}
@media screen and (max-width: 769px){
    .fetal_condition_slide h3,
	.fetal_condition_slide p {text-align:center;}
    .home_bg_2 {
		padding-top:70px;
        padding-bottom: 70px;
        height: 100%;
        min-height:unset;
	}
}


@media (max-width:767px){


	.fetal_condition_slide img {
		display: block;
		margin:0 auto;
	}

	.home_specialised_service_column h4,
	.home_specialised_service_column p {text-align:center;}

	.home_specialised_service_column a.btn {
		margin:0 auto;
		display:block;
		max-width: 135px;
		text-align: center;
	}

	.fetal_condition_slide { padding:0 40px 60px 40px; }

	.fetal_condition_slide h3,
	.fetal_condition_slide p {text-align:center;}

	body.home .video_wrapper {overflow: auto;height:auto;}

	.video_wrapper .video_text { top:32%;}
	.embed-responsive-16by9 {padding:0;}

	.video_wrapper .icon-arrow-down {display:none;}

	/*.band_padder.bottom_only {
		padding-top:0;
	}*/

}