:focus {
	outline: none;
	border: none;
}
.icon-inline {
	display: inline-block;
	vertical-align: middle;
}
h2 .icon-arrow-left {
	margin-right: 10px;
	margin-bottom: 4px;
}
.slick-arrow {
	opacity: .5;
	transition: opacity .1s linear;
}
.slick-arrow:hover {
	opacity: 1;
}
.slick-next:before, .slick-prev:before {
	content: '';
}