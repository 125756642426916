.btn {
	display: inline-block;
	border: 2px solid black;
	background-color: transparent;
	color: #14e9b6;
	font-weight: 700;
	font-family: 'Josefin Sans', sans-serif;
	text-transform: uppercase;
	padding: 4px 15px 0px;
	width: auto;
    line-height: 30px;
}
.btn:hover, .btn:focus, .btn:active {
	background-color: black;
	color: #14e9b6;
	text-decoration: none;
	border: 2px solid black;
	outline: none;
}
.btn-light {
	border: 2px solid white;
}
.btn-light:hover, .btn-light:focus, .btn-light:active {
	background-color: white;
	color: #14e9b6;
	text-decoration: none;
	border: 2px solid white;
	outline: none;
}