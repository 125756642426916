#contactForm {
    width:100%;
    position:relative;
}

#contactForm .form-control {
    border-radius:0;
    border:2px solid #000000;
    position: relative;
    padding:10px 14px;
    font-size:13px;
    height:41px;
}

#contactForm .form-control::-moz-placeholder {
    color: #000000;
    opacity: 1;
}
#contactForm .form-control:-ms-input-placeholder {
    color: #000000;
}
#contactForm .form-control::-webkit-input-placeholder {
    color: #000000;
}

#contactForm textarea.form-control {
    resize:none;
    min-height:200px;
    padding:14px;
    font-size:13px;
}

#contactForm button.btn-dark {
    width:120px;
    margin:0 0 62px 0;
}
#contactForm .error {
     color: red;
}
#contactForm label {
    display: inline;
}
.pre-form-copy p.form_success,
.form_success {
    color: #14e9b6;
    font-weight: 700;
    font-size:14px;
}

#contactForm .form-control:focus {
    -moz-box-shadow:    0px 0px 5px 0px #14e9b6;
    -webkit-box-shadow: 0px 0px 5px 0px #14e9b6;
    box-shadow:         0px 0px 5px 0px #14e9b6;
}