.band.header-branding, .home .band.header-branding.header-branding-shaded {
	background-color: #d0fbf0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99997;
}
.home .header-branding {
	background-color: transparent;
	transition: background-color .15s linear;
}
.header-branding header {
	z-index: 99999;
}
.header_logo {
	float: left;
	margin-top: 19px;
}
.header-branding {
	position: fixed;
	top: 0;
	background-color: #d0fbf0;	
	height: 84px;
}

