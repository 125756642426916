footer.site_footer {
    margin: 0 auto;
    position:relative;
}

footer.site_footer .footer_line {
    border-top: 2px solid #d0fbf0;
    padding-top: 66px;
    width:100%;
    position: relative;
}

body.home footer.site_footer .footer_line {
    border:none;
}

.home footer.site_footer {    
    border-top: none;
}
footer.site_footer h3 {
    font-size: 12px;
    text-transform:uppercase;
    margin:0 0 15px 0;
    line-height:1;
    color:#000000;
    font-family: Raleway, sans-serif;
}
footer.site_footer ul { margin:0; }

footer.site_footer ul li {
    margin:0 0 10px 0;
}
footer.site_footer li a {
    font-size:12px;
    color:#000000;
    text-transform:capitalize;
}
footer.site_footer li a:hover,
footer.site_footer li a:active,
footer.site_footer li a:focus {
    text-decoration:underline;
}
footer.site_footer li a.current {
    font-weight:bold;
    color:#14e9b6;
}
footer.site_footer .icon-footerlogo {
    margin:0 0 20px 0;
}
footer.site_footer .qualifications {    
    font-size:12px;
    color:#000000;
    font-weight:400;
    margin:0 0 36px 0;
}
footer.site_footer .copy {    
    font-weight:400;
}
.credit_line {
    padding: 10px 0;
    display: inline-block;
    vertical-align: middle;
}

.footer_producer {
    background:#f5f5f5;
    position: relative;
    z-index: 2;
}

.footer_line_wrap {
    padding:0;
}

@media (max-width:767px) {

    footer.site_footer .icon-footerlogo {margin:30px 0 20px;}

    footer.site_footer h3 {margin:15px 0 10px;}

    .footer_line_wrap {
        padding:0 15px;
    }

}