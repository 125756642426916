/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-arrow-blk-down {
  background-image: url(../img/spritesheet.png);
  background-position: -172px -177px;
  width: 29px;
  height: 15px;
}
.icon-arrow-blk-up {
  background-image: url(../img/spritesheet.png);
  background-position: 0px -221px;
  width: 29px;
  height: 15px;
}
.icon-arrow-down {
  background-image: url(../img/spritesheet.png);
  background-position: -263px 0px;
  width: 50px;
  height: 50px;
}
.icon-arrow-left {
  background-image: url(../img/spritesheet.png);
  background-position: 0px -256px;
  width: 15px;
  height: 29px;
}
.icon-arrow-leftdark {
  background-image: url(../img/spritesheet.png);
  background-position: -35px -256px;
  width: 15px;
  height: 29px;
}
.icon-arrow-right-dark {
  background-image: url(../img/spritesheet.png);
  background-position: -70px -256px;
  width: 15px;
  height: 29px;
}
.icon-arrow-up {
  background-image: url(../img/spritesheet.png);
  background-position: -49px -221px;
  width: 29px;
  height: 15px;
}
.icon-close {
  background-image: url(../img/spritesheet.png);
  background-position: -188px -130px;
  width: 24px;
  height: 24px;
}
.icon-footerlogo {
  background-image: url(../img/spritesheet.png);
  background-position: 0px -177px;
  width: 152px;
  height: 24px;
}
.icon-logo {
  background-image: url(../img/spritesheet.png);
  background-position: 0px 0px;
  width: 243px;
  height: 45px;
}
.icon-logodark {
  background-image: url(../img/spritesheet.png);
  background-position: 0px -65px;
  width: 243px;
  height: 45px;
}
.icon-logoicon-scroll {
  background-image: url(../img/spritesheet.png);
  background-position: -263px -70px;
  width: 30px;
  height: 37px;
}
.icon-logomobile {
  background-image: url(../img/spritesheet.png);
  background-position: 0px -130px;
  width: 168px;
  height: 27px;
}
.icon-menu {
  background-image: url(../img/spritesheet.png);
  background-position: -263px -127px;
  width: 35px;
  height: 24px;
}
.icon-menudark {
  background-image: url(../img/spritesheet.png);
  background-position: -263px -171px;
  width: 35px;
  height: 24px;
}

@media (-webkit-min-device-pixel-ratio: 2),
       (min-resolution: 192dpi) {
  .icon-arrow-blk-down {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-blk-up {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-down {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-left {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-leftdark {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-right-dark {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-arrow-up {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-close {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-footerlogo {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-logo {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-logodark {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-logoicon-scroll {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-logomobile {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-menu {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
  .icon-menudark {
    background-image: url(../img/spritesheet-2x.png);
    background-size: 313px 285px;
  }
}
