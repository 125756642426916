html, body {
	font-family: 'Raleway', sans-serif;
  font-size: 13px;
  color: black;
}
h1, h2, h3, h4, h5, h6 {
	text-rendering: optimizeLegibility;
	line-height: 1;
	margin-top: 0;
	color: #222;
  font-family: 'Josefin Sans', sans-serif;
}
h1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
h2 {
  font-size: 19px;
  line-height: 21px;
  letter-spacing: .1em;
}
h3 {
  font-size: 16px;
  line-height: 18px;
}
h4, h2.page_h2 {
  font-size: 16px;
  line-height: 18px;
  margin-top:40px;
}
h2.page_h2 {
  text-transform: uppercase;
  margin-top:40px;
}
h5 {
  font-size: 14px;
  line-height: 16px;
}
h6 {
  font-size: 10px;
  line-height: 13px;
}
p {
	margin: auto auto 1.5rem;
}
.p_lede {
  font-size: 15px;
  margin: auto 3em 1.5rem;
}
blockquote + figcaption cite {
	display: block;
	font-size: inherit;
	text-align: right;
}

body {
	word-wrap: break-word;
}

pre code {
	word-wrap: normal;
}

small {
  font-size: 65%;
}

input,
abbr,
acronym,
blockquote,
code,
kbd,
q,
samp,
var {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre {
  white-space: pre;
}

pre code {
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

code {
  white-space: pre;
  font-family: monospace;
}

abbr {
  -webkit-font-variant: small-caps;
  -moz-font-variant: small-caps;
  -ms-font-variant: small-caps;
  font-variant: small-caps;
  font-weight: 600;
  text-transform: lowercase;
  color: gray;
}

abbr[title]:hover {
  cursor: help;
}

.white_text.white_text {
  color: white;
}

/* Links */
main a,
.green_text {
  color: #14e9b6;
}
main a {font-weight:500;}
main a:hover, main a:focus {
  color: #14e9b6;
  text-decoration: underline;
}
.site_footer {
  font-family: 'Raleway', sans-serif;
}
.site_footer h3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
.site_footer ul {
  list-style: none outside none;
  padding-left: 0;
}
.site_footer ul a {
}
.text-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.josefin {
  font-family: 'Josefin Sans', sans-serif;
}
.raleway {
  font-family: 'Raleway', sans-serif;
}

/*.section_header{
    margin-top: 20px;
}*/

/*CONTENT SECTION HEADINGS AND ARROWS*/
section.row h1 {
  text-transform:uppercase;
  font-size:17px;
  font-weight:bold;
  line-height:17px;
  padding:56px 0 8px 28px;
  position:relative;
}

/*PRETERM BIRTH IMAGE AND DESCRIPTION STYLES*/

.preterm_top {
  position:relative;
  width:100%;
  margin:25px 0 0 0;
}

.preterm_top {
  min-height:165px;
}

.preterm_top img,
.preterm_bottom img {
  max-width:100%;
  display:block;
  width:auto;
  margin:auto;
  top:0;
  bottom:0;
  left:0;
  right:0;
  position:absolute;
}

.top_description {
  text-align:center;
  font-family: 'Raleway', sans-serif;
  font-size:12px;
  line-height:14px;
  color:#000000;
  margin:18px 0 45px 0;
}

.top_description span {
  font-size:10px;
  line-height:12px;
  text-align:center;
  display:block;
  margin:15px 0 0 0;
}

.preterm_bottom {
  margin:50px auto 0 auto;
  position: relative;
  width:100%;
  max-width:161px;
  min-height:96px;
  border-bottom:2px solid #14e9b6;
}

.bottom_description {
  text-align:center;
  margin:10px auto 36px;
  font-size:12px;
  line-height:14px;
  color:#000000;
  font-weight:normal;
}

.bottom_description strong {font-weight:bold;}

.pre-form-copy {
  text-align:center;
  margin:72px auto 28px auto;
  font-size:13px;
  font-family:"Raleway",sans-serif;
  color:#000000;
}

.bottom_content_text p {
  text-align:center;
  margin:0 auto;
}

.contact_detail_wrapper {
  margin:58px auto 71px;
}

.contact_detail_wrapper p {
  margin:0 auto 22px;
}

.contact_detail_wrapper p:last-child {
  margin:0;
}
.sidebar h2 {
  text-transform: uppercase;
  font-size: 17px;
}

.page_h2_text {
  padding-left: 20px;
  padding-top: 6px;
  max-width: 92%;
}

.page_h2:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 992px){
  h1, h2, h3, h4, h5, h6 {
    text-rendering: optimizeLegibility;
    line-height: 1;
    margin-top: 0;
    color: #222;
    font-family: 'Josefin Sans', sans-serif;
  }
  h1 {
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
  }
  h2 {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: .1em;
  }
  h3 {
    font-size: 20px;
    line-height: 22px;
  }
  h4, h2.page_h2 {
    font-size: 18px;
    line-height: 20px;
    margin-top:40px;
  }
  h5 {
    font-size: 15px;
    line-height: 18px;
  }
  h6 {
    font-size: 11px;
    line-height: 15px;
  }
}

@media screen and (max-width: 768px){
  
  h1{
    font-size:26px;
    margin-top: 65px;
  }
  h2 {
      font-size: 24px;
  }
    .video_text{
        top: 40%;
    }
  .video_text p {
      font-size: 16px;
  }
}