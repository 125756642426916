.slick-prev.slick-arrow {left:0; z-index:2;}

.slick-next.slick-arrow {right:0; z-index:2;}

h2 .icon-arrow-left {
    margin:0;
}

.slick-slide img {margin:0 auto;}

.slick-slide {min-height:465px;}