html, body {
	background-color: white;
}
.icon {
  display: block;
}
.band {
	width: 100%;
	margin: 0 auto;
	background-color: white;
	z-index: 999;
	position: relative;
}
.band_padder {
	padding-top: 50px;
	padding-bottom: 50px;
}
main {
	margin-top: 20px;
	padding-bottom: 60px;
}
.home main {
	padding-bottom: 0;
}
.video_wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
}
.video_wrapper video {
	z-index: 1;
	position: fixed;
	width: 100%;
}
.video_text {
	width: 100%;
	z-index: 2;
	position: absolute;
	top: 50%;
	margin-top: -80px;
	text-align: center;
	letter-spacing: .2em
}
.video_text h1 {
	letter-spacing: .15em
}
.video_text p {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 600;
}
#main, .site_footer {
	position: relative;
	z-index: 999;
	background-color: white;
}
.site_footer address {
	display: inline-block;
}
.video_arrow_wrapper {
	text-align: center;
	position: absolute;
	width: 100%;
	bottom: 12%;
	left: 0;
	z-index: 999;
}

.mobile {display:none;}
.desktop {display:block;}

.condition_image {margin-top:45px;}

.main_text {
	z-index:3;
	position:relative;
}

.image_overlay {
	position:absolute;
	width:100%;
	height:100%;
	background:#000000;
	z-index:2;
	opacity:0.2;
	top:0;
	left:0;
}


@media screen and (min-width: 992px){

	.mobile {display:none;}
	.desktop {display:block;}
	.video_arrow_wrapper { bottom: 14%; }

}
.video_wrapper .icon-arrow-down {
	margin: 0 auto;
}
.video_wrapper.embed-responsive video {
	height: auto;
}
.page_header_section {
	background:#d0fbf0;
	padding:40px 0 37px 0;
	position:relative;
	margin:84px auto 0;
}
.page_header_section h1 {
	margin:0 auto;
	text-transform:uppercase;
	color:#14e9b6;
	font-size:28px;
	line-height:28px;
	font-weight: 400;
	letter-spacing:0.1em;
}
/* Fetal Conditions Sidebar */
.sidebar {
	border: 1px solid #d0fbf0;
	padding: 23px;
}
.sidebar ul {
	list-style: none;
	padding-left: 0;
}
.sidebar ul li {
	margin-bottom: .75em;
}
.sidebar ul li a {
	color: black;
}
.sidebar ul li.current a {
	font-weight: 700;
	color: #14e9b6;
}
@media (max-width:767px){

	.page_header_section h1 {
		line-height:22px;
		font-size:24px;
	}
	.mobile {display:block;}
	.desktop {display:none;}

	.home main {padding-bottom:30px;}

	footer.site_footer .footer_line {padding-top:32px;}

}
@media screen and (max-width: 991px) and ( min-width: 768px ){
    .sidebar{
        width: 27%;
        margin-left:18px;
    }

}