.flyout-menu {
	position: absolute;
	left: 100%;
	right: 0;
	top: 0;	
	transition: all .15s ease-out;
	background-color: white;
	padding:  95px 35px 36px;
	z-index: 99997;	
}
.nav_closed .flyout-menu {
	width: 454px;
}
.nav_closed .flyout-menu ul {
	width: 100%;
	margin:0;
	padding:0;
}
.navtoggle {
	position: absolute;
	right: 32px;
	top: 30px;
	z-index: 99999;
}
.header_contact_btn {
	right: 113px;
	top: 25px;
	position: absolute;	
	z-index: 99999;
    padding-left: 18px;
    padding-right: 18px; 
}
.flyout-menu ul > li {
	width: 100%;
}
.flyout-menu li {
	margin-bottom: .75em;
}
.flyout-menu li a {
	width: 240px;
	height: 32px;
	line-height: 30px;
	padding: 0;
	text-align: center;
	font-size: 14px;
	font-weight:600;
	text-transform:uppercase;
	display:inline-block;
}
.flyout-menu li.current a {
	color: #14e9b6;
	font-weight: 900;
}

.flyout-menu a:hover {
	text-decoration: underline;
}
.flyout-menu a:focus, .flyout-menu a:active{
    text-decoration: none;
}
.flyout-menu ul {
	list-style: none;
	padding-left: 0;
}
.flyout-menu ul ul.sub-menu {
	display: none;
	padding-left: 10px;
	margin-top: 18px;
}
.flyout-menu ul ul.sub-menu li a {  
	text-transform: none;
	font-size:13px;
}
.nav_overlay {
	background-color: rgba(0,0,0,.5);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 9999;
    cursor: pointer;
}
.flyout-menu .icon-arrow-blk-down, .flyout-menu .icon-arrow-blk-up {
	display: inline-block;
	float: right;
	margin-right: 15px;
}
.has-children a {
	display: inline-block;
	width: 100%;
}

.flyout-menu li {
	height:auto;
	margin-bottom: 20px;
	line-height:1;
}

.flyout-menu li a {
	width:240px;
	font-size:13px;
	line-height:1;
	height:auto;
	padding:0;
	text-align:left;
	color:#000000;
	/*font-family:"Josefin Sans",sans-serif;*/
}

.flyout-menu li.current a {
	font-weight:700;
	color:#14e9b6;
}

.flyout-menu a.fetal_top_level {
	width:215px;
}

.flyout-menu a.fetal_top_level:focus {
	outline:none;
    text-decoration: none;
}

@media screen and (min-width: 992px){
	.flyout-menu > ul > li {
		width: 60%;
	}

	.flyout-menu > ul > li.has-children {
		width: 100%;
	}
}
@media screen and (max-width: 768px){
	h1{font-size:20px;}
	}
@media (max-width: 767px) {

	header .header_logo.icon-logo,
	header .icon-logo,
	header .icon-logodark{
		width: 35px;
	}
	.header_contact_btn {
		display: block;
		left: 0;
		margin: auto;
		right: 0;
		max-width: 135px;
		text-align: center;
	}

	.icon-menu, .icon-menudark {
		right: 15px;
	}
}